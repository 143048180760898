/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {

          $('#menu-link').on('click', function () {
              $('#menu').addClass('open');
              $('body').addClass('menu-open');
              $(".main-menu .item").removeClass("open");
          });

          $('.close').on('click', function () {
               $('#menu').removeClass('open');
               $(".main-menu .item").removeClass("open");
              $('body').removeClass('menu-open');
          });

          // a

          // $(".right-scroll a ").on("click", function (e) {
          //     e.preventDefault();
          //     $('#menu').addClass('open');
          //     $('body').addClass('menu-open');
          // });

          //

          $(".main-menu .item").on("mouseover", function () {
              $(this).addClass("open");
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
      }
    },
      'page_template_template_project': {
          init: function() {
              // JavaScript to be fired on the home page
          },
          finalize: function() {

              // $(document).ready(function () {
              //
              //   $('.right-scroll a').on('click', function () {
              //       window.location.href = '/';
              //   });
              //
              // });

          }
      },

      'page_template_template_info': {
          init: function() {
              // JavaScript to be fired on the home page
          },
          finalize: function() {

              $(document).ready(function () {

                  $('.right-scroll a').on('click', function () {
                      window.location.href = '/';
                  });

              });

          }
      },

      'category': {
          init: function() {
              // JavaScript to be fired on the home page
          },
          finalize: function() {
              //sticky cat

          }
      },
      'search': {
          init: function() {
              // JavaScript to be fired on the home page
          },
          finalize: function() {
              //sticky cat

          }
      },

    'archive': {
        finalize: function() {

        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    // var BODY = $('body');
    // var APP = {};
    //
    // APP.init = function () {
    //     APP.wrap();
    // };
    //
    // APP.wrap = function () {
    //
    //
    // }

})(jQuery); // Fully reference jQuery after this point.

if(document.querySelector('.contact__img')){
    document.querySelector('.contact__img').style.backgroundImage = getComputedStyle(document.querySelector('.loc-img')).backgroundImage;
}



// window.onload = function(){
//     var tempContainer = document.querySelector('.sf-field-post-meta-last_name_initial');
//     var tempArray = [].slice.call(tempContainer.querySelectorAll('.sf-level-0'));
//     tempArray.forEach(function(el, i){
//         if(i == (tempArray.length - 1)){
//             console.log(el); 
//             el.classList.add('filterReset');
//         }
//     });
//     var resetBtn = document.querySelector('.filterReset');
//     resetBtn.onClick(function(){

//     });
// };
